<template>
  <div id="page">
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="8">
        <DataTable
          @tableAction="emittedAction"
          :items="items"
          :headers="headers"
          :itemActions="{
            edit: true,
            preview: false,
            trash: false,
            copy: false,
            stats: false,
            leads: false,
          }"
          :search="search"
          :preloader="fetchingData"
        />
      </v-col>

      <v-col cols="4">
        <Subnavigation
          root="/tech/pages"
          :pageTitle="pageTitle"
        ></Subnavigation>
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fixed
          dark
          fab
          bottom
          right
          color="primary"
          v-bind="attrs"
          v-on="on"
          @click="createNew"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Neues Formular anlegen</span>
    </v-tooltip>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import Subnavigation from "@/components/ui/Subnavigation.vue";
import DataTable from "@/components/ui/DataTable.vue";
export default {
  created: function () {
    this.getData(); // call it immediatly
  },

  name: "Forms",

  components: {
    PageHeader,
    Subnavigation,
    DataTable,
  },

  data() {
    return {
      pageTitle: "Formulare",
      pageDescription:
        "Hier werden Formulare verwaltet. Formulare können einer Landingpage zugewiesen werden.",
      edit: true,
      trash: true,
      copy: false,
      search: "",
      headers: [],
      items: [],
      fetchingData: false,
    };
  },

  methods: {
    async createNew() {
      /* create new entry */
      let response = await this.postRequest("forms"); // await data from mixin

      if (response.status === 200) {
        this.items.push(response.data.data); // mutate

        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde erfolgreich angelegt.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest("forms");
      this.fetchingData = false;

      if (response.status === 200) {
        this.headers = [
          { text: "ID", value: "id", width: "50px" },
          { text: "Titel", value: "title" },
          { text: "", value: "actions", sortable: false, width: "50px" },
        ];

        this.items = response.data.data;
      }
    },

    emittedAction(value) {
      if (value.action === "editItem") {
        this.$router.push({
          name: "tech.page-plugins.forms.:id",
          params: { id: value.itemId },
        });
      }
    },
  },

  mixins: [apiRequest],
};
</script>
